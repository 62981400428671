@import '../../../theme/newVariables';

.arrow {
  position: absolute;
  font-size: $font-size-7;
  width: 3em;
  height: 3em;

  &::before {
    content: '';
    margin: auto;
    display: block;
    width: $az-spacing-zero;
    height: $az-spacing-zero;
    border-style: solid;
  }
}

.popper {
  width: $az-spacing-320;
  z-index: 150;
  &[data-popper-placement*='bottom'] {
    // Popper v2 injects their styles as a element style, so it is required to use !important to be able to apply it
    margin-top: $az-spacing-xxxs !important;
  }

  &[data-popper-placement*='top'] {
    // Popper v2 injects their styles as a element style, so it is required to use !important to be able to apply it
    margin-bottom: $az-spacing-xxxs !important;
  }

  &[data-popper-placement*='bottom'] .arrow {
    top: $az-spacing-zero;
    left: $az-spacing-zero;
    margin-top: -1.6em;
    width: 3em;
    height: 2em;
    font-size: $font-size-6;
    z-index: 9;

    &::before {
      border-width: $az-spacing-zero 2em 2em 2em;
      border-color: transparent transparent $az-white transparent;
      filter: drop-shadow(0px -2.5px 3px $az-grey-2);
    }
  }

  &[data-popper-placement*='top'] .arrow {
    bottom: $az-spacing-neg-5;
    left: $az-spacing-zero;
    margin-bottom: -0.9em;
    width: 3em;
    height: 2em;
    font-size: $font-size-6;
    z-index: 9;

    &::before {
      border-width: 2em 2em $az-spacing-zero 2em;
      border-color: $az-white transparent transparent transparent;
      filter: drop-shadow(0px 3.5px 3px $az-grey-3);
    }
  }

  &[data-popper-placement*='right'] .arrow {
    left: $az-spacing-zero;
    margin-left: -0.9em;
    height: 3em;
    width: 2em;

    &::before {
      border-width: 2em 2em 2em $az-spacing-zero;
      border-color: transparent $az-white transparent transparent;
    }
  }

  &[data-popper-placement*='left'] .arrow {
    right: $az-spacing-zero;
    margin-right: -0.9em;
    height: 3em;
    width: 2em;

    &::before {
      border-width: 2em $az-spacing-zero 2em 2em;
      border-color: transparent transparent transparent $az-white;
    }
  }

  .popperTitle {
    padding: $az-spacing-xxs $az-spacing-xxs $az-spacing-zero;
    margin-bottom: $az-spacing-xxs;
    display: flex;

    h2 {
      border-bottom: $az-spacing-1 solid $az-grey-2;
      padding-bottom: $az-spacing-xxxs;
    }
  }

  .popperContent {
    margin: $az-spacing-zero;
    padding: $az-spacing-zero $az-spacing-xxs $az-spacing-xxs;
  }

  .paper {
    padding: 0;
    margin-bottom: 0;
  }

  .popperTitleText {
    text-align: left;
    line-height: 1;
    padding-left: $az-spacing-zero;
    text-transform: uppercase;
    font-weight: 700;
    font-size: $az-spacing-xxs;
    font-family: $font-az-cond;
  }

  .popperTitleWithoutBorder {
    padding: $az-spacing-zero $az-spacing-xxs;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .popperTitleCloseButton {
    padding: $az-spacing-zero;
    color: $az-grey-5 !important;
  }

  .popperTitleCloseButton:focus-visible {
    background: transparent;
  }
}
